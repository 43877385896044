<template>
    <div>
        <b-alert :show="alert.show" :variant="alert.variant" fade>
      <span class="text-light-75 font-weight-bold d-block font-size-lg">{{
        alert.text
      }}</span>
        </b-alert>
        <!--begin::Advance Table Widget 10-->
        <div class="card card-custom gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 py-5">
                <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">{{
            $t("TAG.TAGS")
          }}</span>
                    <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
            $tc("TAG.SHOW_COUNT", paginatedTags.length, { total: tags.length })
          }}</span>
                </h3>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body py-0">
                <!--begin::Table-->
                <div class="table-responsive" v-if="tags.length">
                    <table
                            class="table table-head-custom table-vertical-center"
                            id="kt_advance_table_widget_4"
                    >
                        <thead>
                        <tr class="text-left">
                            <th class="pl-0" style="min-width: 110px">
                                {{ $t("TAG.UID") }}
                            </th>
                            <th style="min-width: 110px">{{ $t("TAG.STATUS.STATUS") }}</th>
                            <th style="min-width: 110px">{{ $t("TAG.SELLER") }}</th>
                            <th style="min-width: 120px">
                                {{ $t("TAG.LIFECYCLE.LIFECYCLE") }}
                            </th>
                            <th class="pr-0 text-right" style="min-width: 160px">
                                {{ $t("ACTIONS") }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="tag in paginatedTags">
                            <tr v-bind:key="tag.uid">
                                <td class="pl-0">
                                    <router-link
                                            :to="{ name: 'tag', params: { uid: tag.uid } }"
                                            class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                                    >{{ tag.uid }}
                                    </router-link
                                    >
                                </td>
                                <td>
                    <span
                            class="label label-lg label-inline"
                            v-bind:class="`label-light-${
                        parseTagStatus(tag.status)[0]
                      }`"
                    >{{ parseTagStatus(tag.status)[1] }}</span
                    >
                                </td>
                                <td>
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ tag.seller }}</span
                    >
                                </td>
                                <td>
                    <span
                            class="label label-lg label-inline"
                            v-bind:class="`label-light-${
                        tag.sold ? 'success' : 'warning'
                      }`"
                    >{{
                        tag.sold
                          ? $t("TAG.LIFECYCLE.POSTSALE")
                          : $t("TAG.LIFECYCLE.PRESALE")
                      }}</span
                    >
                                </td>
                                <td class="pr-0 text-right">
                                    <a
                                            v-on:click="confirmDelete(tag.uid)"
                                            class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                        <inline-svg
                                src="media/svg/icons/General/Trash.svg"
                                :title="$t('DELETE')"
                        ></inline-svg>
                          <!--end::Svg Icon-->
                      </span>
                                    </a>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                    <b-pagination
                            v-if="tags.length > pagination.per_page"
                            class="pt-5"
                            v-model="pagination.current_page"
                            :total-rows="tags.length"
                            :per-page="pagination.per_page"
                            align="right"
                    >
                    </b-pagination>
                </div>
                <!--end::Table-->
            </div>
            <!--end::Body-->
        </div>
        <!--end::Advance Table Widget 10-->
    </div>
</template>

<script>
    import ApiService from "@/core/services/api.service";
    import Swal from "sweetalert2";

    export default {
        name: "tags",
        data() {
            return {
                tags: [],
                alert: {
                    show: false,
                    message: "",
                    variant: "",
                },
                pagination: {
                    current_page: 1,
                    per_page: 10,
                },
            };
        },
        mounted() {
            if (this.$route.params.id == "new") {
                return;
            }
            ApiService.get("size/" + this.$route.params.id + "/tags")
                .then(({data}) => {
                    this.tags = data;
                })
                .catch(() => {
                    //Just keep it empty
                });
        },
        methods: {
            confirmDelete: function (uid) {
                const tag_index = this.tags.findIndex((tag) => tag.uid === uid);
                const tag = this.tags.find((tag) => tag.uid === uid);
                Swal.fire({
                    title: this.$t("TAG.DELETE_TITLE", {name: tag.uid}),
                    text: this.$t("TAG.DELETE_TEXT"),
                    icon: "error",
                    showCancelButton: true,
                    cancelButtonText: this.$t("CANCEL"),
                    confirmButtonText: this.$t("DELETE"),
                    buttonsStyling: false,
                    customClass: {
                        cancelButton: "btn btn-secondary",
                        confirmButton: "btn btn-danger",
                    },
                    heightAuto: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        ApiService.delete("tag/" + uid)
                            .then(() => {
                                this.tags.splice(tag_index, 1);
                                this.showAlert(
                                    this.$t("TAG.DELETE_SUCCESS", {name: tag.uid}),
                                    "success"
                                );
                            })
                            .catch(() => {
                                this.showAlert(
                                    this.$t("TAG.DELETE_FAILURE", {name: tag.uid}),
                                    "danger"
                                );
                            });
                    }
                });
            },
            showAlert(text, variant) {
                this.alert.text = text;
                this.alert.variant = variant;
                this.alert.show = true;
                setTimeout(() => (this.alert.show = false), 5000);
            },
            parseTagStatus(status) {
                switch (status) {
                    case "REGISTRATION_PENDING":
                        return ["warning", this.$t("TAG.STATUS.REGISTRATION_PENDING")];
                    case "SCAN_PENDING":
                        return ["info", this.$t("TAG.STATUS.SCAN_PENDING")];
                    case "OPERATIONAL":
                        return ["success", this.$t("TAG.STATUS.OPERATIONAL")];
                    default:
                    case "INVALID":
                        return ["danger", this.$t("TAG.STATUS.INVALID")];
                }
            },
        },
        computed: {
            paginatedTags() {
                const start =
                    (this.pagination.current_page - 1) * this.pagination.per_page,
                    end = start + this.pagination.per_page;
                return this.tags.slice(start, end);
            },
        },
    };
</script>
